<template>
    <div class="body">
        <div id="mui-player" class="mui-player">
            <div slot="HD">
                <div class="right-sider" style=""></div> 
            </div>
            <div slot="rate">
                <div class="xj">倍速</div>
            </div>
            <div slot="anthology">
                <div class="xj">选集</div>
            </div>
        </div>
        <div class="section-lession" v-if="info">
            <div class="lession-header">{{info.name}}</div>
            <div class="xuanji inner">
                <strong>选集</strong>
                <div class="xuanji-list">
                    <span 
                        :class="index == cur? 'item cur':'item'"
                        :key="item.id"
                        @click="changeLesson(index)"
                     v-for="(item, index) in list">{{index+1}}</span>
                </div>
            </div>
            <div class="lession-chap-list inner">
                <strong>详情</strong>
                <div class="" v-html="info.description">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import 'mui-player/dist/mui-player.min.css'
    import MuiPlayer from 'mui-player';
    
    // import '../utils/style.js';
    import { mapState } from 'vuex'
    // uploadVideoProcess, finishLessonProcess, 
    import { getCoursewareDetail, uploadViewProcess, finishViewProcess } from '@/api/task.js';
    import { toast, confirm, checkLogin } from '@/utils/common.js';
    // var vr = '../assets/test.mp4';
    // var tid,tluid,lid, timer;
    var tid, tsid, cur, mp, timer, video;
    export default {
        data() {
            return{
                info: null,
                timer2: null,
                tsid: '',
                tid: '',
                cur: 0,
                list: [],
                coursewareUser: null, // 总结果，可能不存在，如果不存在，第一次上传就会插入
                rate: '1.0', // 倍速
                
                // barrageIsShow: true,
                // currentId : 0,
                // barrageLoop: false,
                // barrageList: [],
            }
        },
        // components:{
        //     vueBaberrage
        // },
        activated() {
            window.vm = this;
            tid = this.tid = this.$route.query.id; // 任务id2
            tsid = this.tsid = this.$route.query.tsid;// 任务结果id
            cur = this.cur = +this.$route.query.cur;// 当前课程索引
            checkLogin().then(()=> {
                this.getDetail(tid, tsid);
            })
            
            // lid = this.$route.query.lid; // 课程id
            // tluid = this.$route.query.tluid; // 课程进度id
            // tid = this.$route.query.tid; // 任务ID，用于计算进度，并更新总进度
            // this.getLessions(lid, tluid);
            this.hideTab();
            //console.log(timer, tluid,tid,tid,tluid,lid, timer);
        },
        deactivated() {
            if(mp){
                mp.destroy();
            }
            clearInterval(timer);
            clearInterval(this.timer2);
        },
        computed: mapState(['token']),
        methods:{
            destroy(){
                if(mp){
                    mp.destroy();
                }
                video = null;
                clearInterval(timer);
                clearInterval(this.timer2);
            },
            renderSlotTpl(){
                var list = this.list;
                var html = '';
                for(let i=0, count = list.length; i<count; i++){
                    var cls = 'side-item ellipsis';
                    if(i == this.cur){
                       cls =  'side-item ellipsis cur';
                    }
                    html+= `<div onclick="vm.changeLesson(${i}, 1)" class="${cls}">${i+1}. ${list[i].name}</div>`;
                }
                document.querySelector('.mplayer-sidebar .right-sider').innerHTML = html;
            },
            renderSlotTpl2(){
                var rates = ['0.5', '0.75', '1.0', '1.25', '1.5', '2.0'];
                var html = '';
                for(let i= 0, count = rates.length; i<count; i++){
                    var cls = 'side-item';
                    var item = rates[i];
                    if(item == this.rate){
                       cls =  'side-item cur';
                    }
                    html+= `<div onclick="vm.changeRate(${item}, ${i})" class="${cls}">${item} X</div>`;
                }
                document.querySelector('.mplayer-sidebar .right-sider').innerHTML = html;
            },
            changeRate(rate, index){
                if(rate == this.rate){
                    return;
                }
                video = mp.video();
                this.rate = rate;
                video.playbackRate = Number(rate);
                document.querySelector('.mplayer-sidebar .cur').classList.remove('cur');
                document.querySelector(`.mplayer-sidebar .side-item:nth-child(${index+1})`).classList.add('cur');
            },
            getDetail(tid, tsid){
                getCoursewareDetail(tid, tsid).then((ret)=>{
                    var data = ret.data;
                    this.list = data.lessons;
                    this.info = data.lessons[this.cur];
                    this.coursewareUser = data.coursewareUser;
                    // this.renderSlotTpl(this.list);
                    this.initVideo(this.info);
                });
            },
            
            initVideo(info){
                // console.log('info', info);
                // return;
                var that = this;
                mp = new MuiPlayer({
                    container: '#mui-player',
                    title: '返回',
                    src: info.file_url,
                    poster: info.picture,
                    autoplay: true,
                    autoOrientaion: true,
                    custom: {
                        rightSidebar: [{
                            ///component: <template><div>1</div></template>,
                            slot: 'HD', // 对应定义的 slot 值
                            // width: 200, // 侧栏宽度，string | number
                            oftenShow: true,
                        }],
                        footerControls:[
                            {
                                slot:'rate', // 对应定义的 slot 值
                                position:'right', // 显示的位置，可选 left、right
                                tooltip:'倍速', // 鼠标悬浮在控件上显示的文字提示
                                oftenShow: false, // 是否常显示。默认为false，在 mobile 环境
                                click:function() { // 按钮点击事件回调
                                    that.renderSlotTpl2();
                                    let elm = document.querySelector('.mplayer-sidebar');
                                    elm.classList.add('show');
                                },
                                style:{"width":'50px',"margin-right":"5px"}, // 自定义添加控件样式
                            },
                            {
                                slot:'anthology', // 对应定义的 slot 值
                                position:'right', // 显示的位置，可选 left、right
                                // tooltip:'下一集', // 鼠标悬浮在控件上显示的文字提示
                                oftenShow: false, // 是否常显示。默认为false，在 mobile 环境
                                click:function() { // 按钮点击事件回调
                                    that.renderSlotTpl();
                                    let elm = document.querySelector('.mplayer-sidebar');
                                    elm.classList.add('show');
                                },
                                style:{"margin-right":"5px","width":'50px'}, // 自定义添加控件样式
                            },
                        ],
                    }
                });
                this.bindVideo(info);
                
                mp.on('controls-toggle', (evt) => {
                    let elm = this.$el.querySelector('.mplayer-sidebar');
                    if (!evt.show) {
                        
                       elm && elm.classList.remove('show');
                    }
                })
                
                // 全屏事件 ，控制头部按钮显示（全屏显示，不全屏不显示）
                // 也间接控制 右边选集菜单
                mp.on('fullscreen-change', (evt) => {
                    let elm = this.$el.querySelector('#mui-player');
                    if (evt.direction == 'landscape') {
                        // this.screenfull = true;
                        elm.classList.add('full-screen');
                        // alert('全屏');
                    } else {
                        // alert('退出全屏');
                        elm.classList.remove('full-screen');
                    }
                })
            },
            changeLesson(index){
                if(index == this.cur){
                    return;
                }
                clearInterval(timer);
                clearInterval(this.timer2);
                // this.destroy();
                // this.initVideo(this.info);
                // 全屏侧边栏点击
                
                var last = document.querySelector('.mplayer-sidebar .cur');
                last && last.classList.remove('cur');
                var cur = document.querySelector(`.mplayer-sidebar .side-item:nth-child(${index+1})`);
                cur && cur.classList.add('cur');
                this.rate = '1.0';
                this.cur = index;
                this.info = this.list[index];
                mp.reloadUrl(this.info.file_url);
                this.bindVideo(this.info);
            },
            
            bindVideo(data){
                video = mp.video();
                //video.addEventListener();
                // 播放完毕，上传课件完成
                // 开始播放，再次播放 上传进度
                // 停止播放 不上传进度
                // 暂停 不上传进度
                // 如果是重看，就不需要上传数据
                // console.log('tiaojian', data.process.total_num, data.process.speed_num);
                // 如果已经观看，那就赋予给进度
                var clu = data.clu;
                if(clu && clu.speed_num 
                  && clu.total_num != clu.speed_num){
                    video.currentTime = clu.speed_num;
                    cur = Number(clu.speed_num);
                }
                // 记录时间
                this.timer2 = setInterval(()=> {
                   if(video.currentTime - cur> 20){
                       video.currentTime = cur;
                   }else{
                       cur = video.currentTime;
                   }
                }, 500)
                
                if(clu && clu.total_num 
                  && clu.total_num == clu.speed_num){
                    return;
                }
                
                var that = this;
                var fn = ()=>{
                    timer = setInterval(()=> {
                       var min = Math.floor(video.currentTime);
                        that.uploadProcess(min);
                    }, 5000)
                }
                video.addEventListener('error', () => {
                  clearInterval(timer);
                  clearInterval(this.timer2);
                });
                
                video.addEventListener('ended', () => {
                  clearInterval(timer);
                  clearInterval(this.timer2);
                  // 更新总进度
                  that.finishLessonProcess();
                  // 如果当前不是第最后一个，提示播放下一个
                  if(this.cur != this.list.length - 1){
                      confirm('播放完毕，点击确定自动播放下一段', ()=> {
                          this.changeLesson(this.cur+1);
                      })
                  }
                });
                
                video.addEventListener('play', () => {
                  
                  console.log('start');
                });
                fn();
            },
            uploadProcess(min){
                // 上传进度 id, 视频进度
                var data ={
                    speed_num: min
                }
                this.info.speed_num = min;
                this.$set(this.list, this.cur, this.info);
                // console.log(data);
                var cluid = this.info.clu? this.info.clu.id: '0';
                var params = {
                    cid: tid,
                    clid: this.info.id
                };
                uploadViewProcess(cluid, data, params).then((ret)=> {
                    if(!this.info.clu){
                       this.info.clu = ret.data.clu;
                       this.coursewareUser = ret.data.cu;
                    }
                    
                    this.info.clu.speed_num = min;
                    this.$set(this.list, this.cur, this.info);
                }).catch((err)=>{
                    console.log(toast, err);
                    // toast(err.msg|| '上传视频进度失败！', 'error')
                })
            },
            finishLessonProcess(){
                // 完成该视频
                var data = {
                    lesson: this.info.name,
                };
                finishViewProcess(this.info.clu.id, tid, data).then((ret)=>{
                    if(ret){
                        this.info.clu.speed_num = this.info.clu.total_num;
                        this.$set(this.list, this.cur, this.info);
                    }
                }).catch((err)=>{
                    toast(err.msg|| '更新总进度失败！', 'error')
                })
            },
            hideTab(){
                //console.log(document.querySelector('#vantab'));
                document.querySelector('#vantab').classList.add('tab-hide');
            }
        }
    }
</script>

<style scoped>
    @import url('../../assets/css/common.css');
    .body{
        position: relative;
    }
    .baberrage{
        position: absolute;
        top: 0;
        pointer-events: none;
    }
    .section-lession{
        font-size: 16px;
    }
    .section-lession .lession-header{
        border-bottom: 1px solid #f2f2f2;
        margin-bottom: 1.2rem;
        padding: 1.6rem 1.2rem;
        font-weight: bold;
    }
    .lession-chap-list{
        padding: 10px 10px;
        font-size: 14px;
    }
    .lession-chap-list strong{
        font-size: 16px;
    }
    .xuanji strong{
        display: inline-block;
        margin-bottom: 10px;
    }
    .xuanji-list{
        display: flex;
        flex-wrap: wrap;
        
    }
    .inner{
        width: 34.5rem;
        margin: 0 auto;
    }
    .xuanji-list .item{
        display: block;
        width: 2.8rem;
        height: 2.8rem;
        line-height: 2.8rem;
        text-align: center;
        border: 1px solid #999;
        color: #999;
        margin-right: 0.5rem;
        margin-bottom: 0.5rem;
    }
    /* .xuanji-list .item:nth-child(10n){
         margin-right: 0;
    } */
    .xuanji-list .cur{
        border: 1px solid #465acd;
        color: #465acd;
        font-weight: bold;
    }
    .mui-full-player{
        position: fixed;
        left: 0;
        top: 0;
        width: 100vh !important;
        height: 100vw !important;
        transform-origin: top left;
        transform: rotate(90deg) translateY(-100vmin);
        z-index: 9999;
    }

</style>